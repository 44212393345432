import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Button from "../components/button"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div style={{textAlign: "center", marginBottom: `1.45rem`}}>
      <h1 className='text-glow' style={{lineHeight: '3rem'}}>Apps built Cleanly and Clearly</h1>
      <h5 className='white-text-glow' style={{marginBottom: '2rem', fontSize: '22px'}}>We build iPhone applications that people love.</h5>
      <Button 
        title={"Contact Us"}
        className="custom-button"
      >
      </Button>
      <Button
        title={"Learn More"} 
        className="custom-button secondary"
      />
      <StaticImage
        src="../images/development.png"
        width={800}
        quality={95}
        placeholder="blurred"
        formats={["auto", "webp", "avif"]}
        alt="app screnshots"
        imgStyle={{ borderRadius: '3%' }}
        
      />
    </div>
    <div style={{textAlign: 'center', display: "flex", flexDirection: "column"}}>
      <h2 className="text-glow" style={{lineHeight: '2rem'}}>
        App Development
      </h2>
      <h3 className="text-glow" style={{lineHeight:'1.75rem'}}>Apple Award worthy applications created by professional developers</h3>
      <div style={{textAlign: 'center', display: "flex", 
          flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center',
      }}>
        <div className="custom-card">
          <p className="card-text-glow" style={{textAlign: 'center', marginBottom: "0.50rem"}}>
            <b>Clean Development</b>
          </p>
          <b className="text-sm subtitle-text-glow">The cleanest development process<br></br>in the industry.</b>
        </div>
        <div className="custom-card">
          <p className="card-text-glow" style={{textAlign: 'center', marginBottom: "0.50rem"}}>
            <b>Clear Design</b>
          </p>
          <b className="text-sm subtitle-text-glow">Simple and easy to use designs<br></br>that people love.</b>
        </div>
        <div className="custom-card">
          <p className="card-text-glow" style={{textAlign: 'center', marginBottom: "0.50rem"}}>
            <b>App Advice</b>
          </p>
          <b className="text-sm subtitle-text-glow">Seasoned App Veterans.<br></br>Let us guide you.</b>
        </div>
      </div>
      <h2 className="text-glow" style={{marginTop: "2rem", lineHeight: '1.75rem'}}>
        Our Work
      </h2>
      <h3 className="text-glow" style={{marginBottom: "1rem", lineHeight: '1.3rem'}}>Taking advantage of Apple's latest and greatest features</h3>
      <div style={{display: "flex", flexDirection: "column",  alignItems: 'center', justifyContent: 'center'}}>
        <div className="app-card" style={{
          display: "flex"
        }}>
          <StaticImage
            src="../images/blipp.png"
            width={300}
            quality={95}
            placeholder="blurred"
            formats={["auto", "webp", "avif"]}
            alt="app screnshots"
            imgStyle={{ borderRadius: '3%' }}
            style={{boxShadow: "1px 1px 7.5px #fbf590", borderRadius: '3%'}}
          />
          <div style={{margin: '1rem', display: 'flex', flexDirection: 'column'}}>
            <h3 className="app-card-title-glow">blipp.</h3>
            <div className="font-secondary app-card-text-glow">
              <p>
                blipp. is a social media application<br></br>that let's you discover what's around you.
              </p>
              <p>
                Message friends, send photos, and meet new people.
              </p>
            </div>
          </div>
        </div>
        <div className="app-card reverse" style={{
          display: "flex"}}>
          <StaticImage
            src="../images/your-albums.png"
            width={300}
            quality={95}
            placeholder="blurred"
            formats={["auto", "webp", "avif"]}
            alt="app screnshots"
            imgStyle={{ borderRadius: '3%' }}
            style={{boxShadow: "1px 1px 7.5px white", borderRadius: '3%'}}
          />
          <div style={{margin: '1rem', display: 'flex', flexDirection: 'column'}}>
            <h3 className="app-card-title-glow">Your Albums</h3>
            <div className="font-secondary app-card-text-glow">
              <p>
                Your Albums is an Apple Music app<br></br>that let's you add music to your home screen.
              </p>
              <p>
                Add and customize your music. <br></br>Build your vibe.
              </p>
            </div>
          </div>
          
        </div>
      </div>
      <div className="callout-card text-glow">
        <h1 style={{marginTop: "2rem", fontSize: '64px', marginBottom: '45px'}}>
          Ready to Build?
        </h1>
        <h2 style={{lineHeight: '2rem', fontSize: '44px'}}>We are.</h2>
        <Button 
          title={"Contact Us To Get Started"}
          className="gradient-button full-screen"
          >
        </Button>
      </div>
    </div>
  </Layout>
)

export default IndexPage
